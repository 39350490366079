<template>
  <nav-container>
    <template v-slot:headerName>
      <div class="title">
        Users
      </div>
    </template>
    <template v-slot:listItems>
      <ul class="menu">
        <li
          v-if="currentUser.permissions.can_read_discussions"
          :class="assetClasses('discussions')"
          class="nav-button discussions"
        >
          <a href="/discussions">Discussions</a>
        </li>
        <li
        v-if="canAccessAssignments"
          :class="assetClasses('asset_assignments')"
          class="nav-button assignments"
        >
          <a href="/asset_assignments">Assignments</a>
        </li>
      </ul>
    </template>
  </nav-container>
</template>

<script>
import { mapGetters } from 'vuex'
import NavContainer from '../../shared/NavContainer'
export default {
  components: {
    NavContainer
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'canAccessAssignments'
    ])
  },
  methods: {
    assetClasses (type) {
      // Using slice to singularize the type
      const singularType = type.slice(0, -1)
      const active = this.$router.isRoute(singularType)

      return {
        'nav-item': true,
        'top-active': active
      }
    }
  }
}
</script>
