<template>
  <div class="publishing">
    <top-nav />
    <discussion-search v-if="this.$route.name == 'discussions'" />
    <assignment-search v-if="this.$route.name == 'asset_assignments'" />
  </div>
</template>

<script>
import TopNav from './TopNav'
import DiscussionSearch from '../../shared/Search'
import AssignmentSearch from '../../shared/Search'

export default {
  components: {
    DiscussionSearch,
    AssignmentSearch,
    TopNav
  }
}
</script>
